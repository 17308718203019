import axios from 'axios';
import { BrowserHistory } from 'history';
import { REGISTRO } from 'navigation';
import { ocultarLoading } from 'utils';
import { HttpStatusCodeEnum } from './HttpStatusCodeEnum';

const ErrorInterceptor = (t: any, history: BrowserHistory) => {
  /**
   * Array que contiene las peticiones que son excluidas de ser enviadas al home de onboarding
   */
  const valoresExcluidos = ['clave/validacion-solicitud/', 'public/token-v3', 'public/token-v2', 'clave/cambio-clave', 'public/token'];

  axios.interceptors.response.use(
    response => {
      ocultarLoading();
      return response;
    },
    error => {
      ocultarLoading();
      const ERROR_MSG = t('errors.generic');
      if (error.response?.data) {
        if (error.response?.data?.message) {
          error.response.data.mensaje = error.response.data?.message || ERROR_MSG;
        }
      } else {
        error.response = { ...error.response, data: { mensaje: ERROR_MSG } };
      }
      if (error.response.status === HttpStatusCodeEnum.INTERNAL_SERVER_ERROR) {
        error.response.data.mensaje = ERROR_MSG;
      }
      if (
        error.response.status === HttpStatusCodeEnum.FORBBIDEN &&
        !valoresExcluidos.some(valor => error.response.config.url.includes(valor))
      ) {
        history.push(REGISTRO);
        error.response.data.mensaje = t('errors.venceToken');
      }
      return Promise.reject(error);
    }
  );
};

export default ErrorInterceptor;
