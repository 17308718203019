import { createStyles, makeStyles, Theme } from '@material-ui/core';

const appStyles = makeStyles((theme: Theme) =>
  createStyles({
    variantSuccess: {
      backgroundColor: 'var(--success)',
      fontWeight: 700,
    },
    variantError: {
      backgroundColor: 'var(--rojo-error)',
      fontWeight: 700,
      [theme.breakpoints.down('sm')]: {
        padding: '.375rem 1rem .375rem 1.25rem',
      },
    },
    variantInfo: {
      backgroundColor: 'var(--azul-info)',
      fontWeight: 700,
    },
    variantWarning: {
      backgroundColor: 'var(--amarillo-l44)',
      fontWeight: 700,
    },
    MuiSvgIconRoot: {
      color: 'red',
      fontSize: '16px',
    },
  })
);

export default appStyles;
