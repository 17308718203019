import { createSlice } from '@reduxjs/toolkit';
import { OnBoardingState } from './onBoardingState.interface';

const initialState: OnBoardingState = {
  numeroCelular: '',
  nombre: '',
  apellido: '',
  email: '',
  recaptcha: '',
  id: '',
  clave: '',
  numeroServicio: '',
  rut: '',
  token: '',
  tipoIngreso: null,
  migrado: false,
  empresa: {
    descripcion: '',
    codigoEmpresa: '',
    numeroEmpresa: 0,
  },
  servicios: [],
  usuarioRestablecer: {
    idSolicitud: '',
    email: '',
  },
  idGoogle: '',
  modalPaginaNoDisponible: {
    tiempoCookie: 0,
    activacionModal: false,
  },
  flagCargado: false,
};

export const onBoardingSlice = createSlice({
  name: 'onBoarding',
  initialState,
  reducers: {
    actualizarUsuario: (state, action) => {
      state.nombre = action.payload.nombre || state.nombre;
      state.apellido = action.payload.apellido || state.apellido;
      state.email = action.payload.email || state.email;
      state.numeroCelular = action.payload.numeroCelular || state.numeroCelular;
      state.recaptcha = action.payload.recaptcha || state.recaptcha;
      state.id = action.payload.id || state.id;
      state.clave = action.payload.clave || state.clave;
      state.numeroServicio = action.payload.numeroServicio || state.numeroServicio;
      state.rut = action.payload.rut || state.rut;
      state.tipoIngreso = action.payload.tipoIngreso || state.tipoIngreso;
      if (action.payload.token) {
        localStorage.setItem('token', action.payload.token);
      }
      state.migrado = action.payload.migrado || state.migrado;
      state.token = action.payload.token || state.token;
      state.empresa = action.payload.empresa || state.empresa;
      state.servicios = action.payload.servicios ? [...action.payload.servicios] : state.servicios;
      state.idGoogle = action.payload.idGoogle || state.idGoogle;
    },
    actualizarUsuarioAutorizadoCambiarPassword: (state, action) => {
      state.usuarioRestablecer.email = action.payload;
    },
    actualizarUsuarioAutorizadoCambiarPasswordId: (state, action) => {
      state.usuarioRestablecer.idSolicitud = action.payload;
    },
    actualizarEstadoModalPaginaNoDisponible: (state, action) => {
      state.modalPaginaNoDisponible.tiempoCookie = action.payload.tiempoCookie;
      state.modalPaginaNoDisponible.activacionModal = action.payload.activacionModal;
    },
    actualizarEstadoFlag: (state, action) => {
      state.flagCargado = action.payload;
    },
  },
});

export const {
  actualizarUsuario,
  actualizarUsuarioAutorizadoCambiarPassword,
  actualizarUsuarioAutorizadoCambiarPasswordId,
  actualizarEstadoModalPaginaNoDisponible,
  actualizarEstadoFlag,
} = onBoardingSlice.actions;

export default onBoardingSlice.reducer;
