import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enCommon from './en/common.json';
import enErrors from './en/errors.json';
import enMigracion from './en/migracion.json';
import enOnBoarding from './en/onboarding.json';
import enRestablecer from './en/restablecer.json';
import esCommon from './es/common.json';
import esErrors from './es/errors.json';
import esMigracion from './es/migracion.json';
import esOnBoarding from './es/onboarding.json';
import esPolitica from './es/politica.json';
import esRestablecer from './es/restablecer.json';

export const resources = {
  en: {
    translation: enCommon,
    onboarding: enOnBoarding,
    politica: esPolitica,
    migracion: enMigracion,
    restablecer: enRestablecer,
    errors: enErrors,
  },
  es: {
    translation: esCommon,
    onboarding: esOnBoarding,
    politica: esPolitica,
    migracion: esMigracion,
    restablecer: esRestablecer,
    errors: esErrors,
  },
} as const;

i18n.use(initReactI18next).init({
  lng: 'es',
  resources,
  ns: ['translation', 'onboarding', 'migracion', 'restablecer'],
});
