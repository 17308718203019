import { Grid, Link } from '@material-ui/core';
import { Typography } from '@saesa/ui-kit-front';
import imgError from 'assets/images/saesa-error.svg';
import { Trans, useTranslation } from 'react-i18next';
import useStyles from './NotfoundStyle';

const NotFound = () => {
  const { t } = useTranslation();
  const styles = useStyles();
  return (
    <section>
      <Grid container>
        <Grid item md={8} sm={12}>
          <figure className={styles.contenedor}>
            <img src={imgError} alt="" className={styles.icono} />
          </figure>
        </Grid>
      </Grid>
      <Grid className={styles.contenido}>
        <Grid className={styles.texto}>
          <Typography className={styles.title} variant="emphasis">
            {t('errors.404.title')}
          </Typography>
          <Typography strong="semi-bold" className={styles.subtitle} variant="h2">
            {t('errors.404.subTitle')}
          </Typography>
          <Typography variant="h3">
            <Trans i18nKey="errors.404.message">
              Estamos trabajando para solucionarlo, por favor intente mas tarde o
              <Link href="https://www.gruposaesa.cl/saesa/consultas-y-reclamos/">comunícate con nosotros</Link>
            </Trans>
          </Typography>
        </Grid>
      </Grid>
    </section>
  );
};

export default NotFound;
