import { useEffect } from 'react';

const handleWindowBeforeUnload = () => window.confirm();

export const useAvoidBeforeUnload = () => {
  useEffect(() => {
    window.onbeforeunload = handleWindowBeforeUnload;
    return () => {
      window.onbeforeunload = null;
    };
  }, []);
};
