export const mostrarLoading = () => {
  let contador: string | number | null = sessionStorage.getItem('loading');
  contador = parseInt(contador!, 10) + 1;
  sessionStorage.setItem('loading', contador.toString());

  const loading = document.getElementById('loading');
  loading?.classList.add('backdrop--show');
};

export const ocultarLoading = () => {
  let contador: string | number | null = sessionStorage.getItem('loading');
  contador = parseInt(contador!, 10);
  if (contador > 0) {
    contador = contador > 0 ? contador - 1 : 0;
    sessionStorage.setItem('loading', contador.toString());
  }

  if (contador === 0) {
    const loading: any = document.querySelectorAll('#loading');
    loading.forEach((item: any) => item.classList.remove('backdrop--show'));
  }
};
