import axios from 'axios';
import { mostrarLoading } from 'utils';

const AuthInterceptor = () => {
  axios.interceptors.request.use(request => {
    const apiKey = `${process.env.REACT_APP_HEADER_API_KEY}`;
    const regexFeatureFlagIgnore = new RegExp(/\/featureFlag.json/g);
    mostrarLoading();
    const token = localStorage.getItem('token');
    if (!regexFeatureFlagIgnore.test(request.url!)) {
      request.headers['x-api-key'] = apiKey;

      if (!token || request.headers.Authorization) {
        request.headers.Authorization = request.headers.Authorization || null;
      } else {
        request.headers.Authorization = `Bearer ${token}`;
      }
    }

    return request;
  });
};

export default AuthInterceptor;
