import TagManager from 'react-gtm-module';
import { EventoAnalyticsModel } from './models';

declare global {
  interface Window {
    dataLayer: any;
  }
}
class AnalyticsService {
  static inicializarGTM() {
    const tagManagerArgs = {
      gtmId: String(process.env.REACT_APP_GTM),
    };
    TagManager.initialize(tagManagerArgs);
  }

  static enviarTag(ev: EventoAnalyticsModel) {
    window.dataLayer?.push({
      event: ev.evento,
      attributes: {
        eventLabel: ev.nombre,
        eventCategory: ev.categoria,
        eventId: process.env.REACT_APP_ANALYTICS,
      },
    });
  }
}
export default AnalyticsService;
