import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { BrowserHistory } from 'history';
import React, { Component, ReactNode } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ai } from './TelemetryService';
/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */

interface Props extends RouteComponentProps {
  history: BrowserHistory;
  children: ReactNode;
  conectionString: string;
  after?: () => void;
}
interface State {
  initialized: boolean;
}

class TelemetryProvider extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      initialized: false,
    };
  }

  componentDidMount() {
    const { history, conectionString, after } = this.props;
    const { initialized } = this.state;
    const AppInsightsInstrumentationKey = conectionString;
    if (!initialized && Boolean(AppInsightsInstrumentationKey) && Boolean(history)) {
      ai.initialize(AppInsightsInstrumentationKey, history);
      this.setState({ initialized: true });
    }

    if (after) {
      after();
    }
  }

  render() {
    const { children } = this.props;
    return <>{children}</>;
  }
}

export default withRouter(withAITracking(ai.reactPlugin, TelemetryProvider));
