import '@saesa/ui-kit-front/lib/esm/assets/fonts/stylesheet.css';
import '@saesa/ui-kit-front/lib/esm/styles/colors.css';
import '@saesa/ui-kit-front/lib/esm/styles/reset.css';
import '@saesa/ui-kit-front/lib/esm/styles/styles.css';
import { AuthInterceptor } from 'config';
import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { store } from 'store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AnalyticsService from './services/AnalyticsService';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

/**
 * Metodo que inicializa GTM
 */
AnalyticsService.inicializarGTM();

const tagManagerArgs = {
  gtmId: String(process.env.REACT_APP_GTM),
};

TagManager.initialize(tagManagerArgs);
sessionStorage.setItem('loading', '0');
AuthInterceptor();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
