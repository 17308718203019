import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, DistributedTracingModes, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { BrowserHistory } from 'history';

let reactPlugin: ReactPlugin;
let appInsights: any;

/**
 * Create the App Insights Telemetry Service
 * @return {{reactPlugin: ReactPlugin, appInsights: Object, initialize: Function}} - Object
 */
const createTelemetryService = () => {
  /**
   * Initialize the Application Insights class
   * @param {string} connectionString - Application Insights Instrumentation Key
   * @param {Object} browserHistory - client's browser history, supplied by the withRouter HOC
   * @return {void}
   */
  const initialize = (connectionString: string, browserHistory: BrowserHistory) => {
    if (!browserHistory) {
      throw new Error('Could not initialize Telemetry Service');
    }
    if (!connectionString) {
      throw new Error('Instrumentation key not provided in ./src/telemetry-provider.jsx');
    }

    reactPlugin = new ReactPlugin();

    appInsights = new ApplicationInsights({
      config: {
        connectionString,
        maxBatchInterval: 0,
        disableFetchTracking: false,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: {
            history: browserHistory,
          },
        },
        enableAutoRouteTracking: true,
        distributedTracingMode: DistributedTracingModes.W3C,
        samplingPercentage: Number(process.env.REACT_APP_TELEMETRY_SAMPLING_PERCENTAGE),
      },
    });

    appInsights.loadAppInsights();
    appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
      envelope.tags = envelope.tags || [];
      envelope.tags.push({ 'ai.cloud.role': process.env.REACT_APP_TELEMETRY_ROLE });
    });
  };

  return { reactPlugin, appInsights, initialize };
};

export const ai = createTelemetryService();
export const getAppInsights = () => appInsights;
