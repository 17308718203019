export enum TipoIngreso {
  APPLE = 'APPLE',
  GMAIL = 'GMAIL',
  NORMAL = 'NORMAL',
}

// hashmap de TipoIngreso
export const TipoIngresoMap = {
  [TipoIngreso.APPLE]: 'apple',
  [TipoIngreso.GMAIL]: 'google',
  [TipoIngreso.NORMAL]: 'otrosCorreos',
};
