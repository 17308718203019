import AnalyticsService from 'services/AnalyticsService';
import { TipoIngreso, TipoIngresoMap } from 'config';
import { EventosGtmSaesa } from './gtmEvents';

/**
 * Metodo que envia un evento tag al presionar btn de ingreso con cuenta apple en registro.
 */
export const envioEventoTagIngresarRegistroCuentaApple = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.registro.btnIngresarCuentaApple);
};

/**
 * Metodo que envia un evento tag al presionar btn de ingreso con cuenta apple en registro redirecciona a validacion cliente.
 */
export const envioEventoTagIngresarRegistroCuentaAppleRedireccionValidacionCliente = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.registro.btnIngresarCuentaAppleRedireccionValidacionCliente);
};

/**
 * Metodo que envia un evento tag al presionar btn de ingreso con cuenta apple en registro redirecciona consentimiento.
 */
export const envioEventoTagIngresarRegistroCuentaAppleRedireccionConsentimientoBoleta = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.registro.btnIngresarCuentaAppleRedireccionConsentimientoBoleta);
};

/**
 * Metodo que envia un evento tag al presionar btn de ingreso con cuenta apple en registro redirecciona a datos personales.
 */
export const envioEventoTagIngresarRegistroCuentaAppleRedireccionDatosPersonales = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.registro.btnIngresarCuentaAppleRedireccionDatosPersonales);
};

/**
 * Metodo que envia un evento tag al presionar btn de ingreso con cuenta apple en registro redirecciona a datos personales.
 */
export const envioEventoTagIngresarRegistroCuentaAppleErrorRedireccionInicio = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.registro.btnIngresarCuentaAppleErrorRedireccionInicio);
};

/**
 * Metodo que envia un evento tag al presionar btn de ingreso login.
 */
export const envioEventoTagIngresarLogin = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.registro.btnIngresarLogin);
};

/**
 * Metodo que envia un evento tag al presionar btn de ingreso con cuenta google exitoso en registro
 */
export const envioEventoTagIngresarRegistroCuentaGoogleExitoso = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.registro.btnIngresoExitosoCuentaGoogle);
};

/**
 * Metodo que envia un evento tag al presionar btn de ingreso con cuenta google exitoso en registro
 */
export const envioEventoTagIngresarRegistroCuentaGoogleFallido = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.registro.btnIngresoFallidoCuentaGoogle);
};

/**
 * Metodo que envia un evento tag al presionar btn de ingreso con cuenta google exitoso en registro de manera forzada
 */
export const envioEventoTagIngresarRegistroCuentaGoogleForzado = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.registro.btnIngresoForzadoCuentaGoogle);
};

/**
 * Metodo que envia un evento tag al presionar btn registrarme flujo de registro exitoso
 */
export const envioEventoTagRegistroOnboardingExitoso = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.registro.btnRegistroOnboardingExitoso);
};

/**
 * Metodo que envia un evento tag al presionar btn registrarme flujo de registro fallido
 */
export const envioEventoTagRegistroOnboardingFallido = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.registro.btnRegistroOnboardingFallido);
};

/**
 * Metodo que envia un evento tag al presionar btn registrarme flujo de registro fallido
 */
export const envioEventoTagRegistroOnboardingValidacionEmailFallidoAvanzaEtapaValidacionEmail = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.registro.btnRegistroOnboardingFallidoAvanzaValidacionEmail);
};

/**
 * Metodo que envia un evento tag al presionar btn registrarme flujo de registro fallido
 */
export const envioEventoTagRegistroOnboardingCompletarDatosFallidoAvanzaDatosPersonales = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.registro.btnRegistroOnboardingFallidoAvanzaDatosPersonales);
};

/**
 * Metodo que envia un evento tag al presionar btn registrarme flujo de registro fallido
 */
export const envioEventoTagRegistroOnboardingValidacionEmailVolverARegistro = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.registro.btnRegistroOnboardingVolverARedireccion);
};

/**
 * Metodo que envia un evento tag al presionar btn registrarme flujo de registro fallido
 */
export const envioEventoTagRegistroOnboardingCompletarDatosFallidoAvanzaConsentimiento = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.registro.btnRegistroOnboardingFallidoAvanzaConsentimiento);
};

/**
 * Metodo que envia un evento tag al presionar btn registrarme flujo de registro fallido
 */
export const envioEventoTagRegistroOnboardingValidacionEmailFallido = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.registro.btnRegistroOnboardingValidacionEmailFallido);
};

/**
 * Metodo que envia un evento tag al presionar btn registrarme flujo de registro fallido
 */
export const envioEventoTagRegistroOnboardingCompletarDatosFallido = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.registro.btnRegistroOnboardingCompletarDatosFallido);
};

/**
 * Metodo que envia un evento tag al presionar btn registrarme flujo de registro fallido
 */
export const envioEventoTagRegistroOnboardingConsentimientoFallido = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.registro.btnRegistroOnboardingConsentimientoFallido);
};

/**
 * Metodo que envia un evento tag al presionar btn registrarme flujo de registro forzado
 */
export const envioEventoTagRegistroOnboardingForzado = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.registro.btnRegistroOnboardingForzado);
};

/**
 * Metodo que envia un evento tag al iniciar proceso onboarding error usuario ya registrado
 */
export const envioEventoTagRegistroOnboardingUsuarioYaRegistrado = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.registro.registroOnboardingUsuarioYaRegistrado);
};

/**
 *  Metodo que envia tag al validar codigo de confirmacion exitoso
 */
export const envioEventoTagValidarCodigoConfirmacionExitoso = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.registro.btnValidaEmailRegistroExistoso);
};

/**
 * Metodo que envia tag al validar codigo de confirmacion expirado
 */
export const envioEventoTagValidarCodigoConfirmacionExpirado = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.registro.btnValidaEmailRegistroExpirado);
};

/**
 * Metodo que envia un evento tag al ingresar a vista de expiracion por numero de intentos permitidos
 */
export const envioEventoTagRegistroValidarNumeroIntentosPermitidos = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.registro.ingresoVistaExpiracionEmailNumeroIntentosPermitidos);
};

/**
 * Metodo que envia tag al reenviar codigo de confirmacion
 */
export const envioEventoTagEnviarCodigoConfirmacion = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.registro.btnEnviarCodigoConfirmacion);
};

/**
 * Metodo que envia tag al reenviar codigo de confirmacion
 */
export const envioEventoTagReenviarCodigoConfirmacion = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.registro.btnReenviarCodigoConfirmacion);
};

/**
 * Metodo que envia tag al validar cliente
 */
export const envioEventoTagValidacionCliente = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.registro.btnValidarCliente);
};

/**
 * Metodo que envia tag al enviar datos personales de registro de forma exitosa
 */
export const envioEventoTagRegistroDatosPersonalesExitoso = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.registro.btnRegistroDatosPersonalesExitoso);
};

/**
 * Metodo que envia tag al enviar datos personales de registro de forma fallida
 */
export const envioEventoTagRegistroDatosPersonalesFallido = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.registro.btnRegistroDatosPersonalesFallido);
};

/**
 * Metodo que envia tag al enviar consentimiento de boleta de forma exitosa en registro
 */
export const envioEventoTagRegistroEnvioConsentimientoBoletaExitoso = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.registro.btnRegistroEnvioConsentimientoBoletaExitoso);
};

/**
 * Metodo que envia tag al enviar consentimiento de boleta de forma fallida en registro
 */
export const envioEventoTagRegistroEnvioConsentimientoBoletaFallido = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.registro.btnRegistroEnvioConsentimientoBoletaFallido);
};

/**
 * Metodo que envia tag al completar registro onboarding
 */
export const envioEventoTagRegistroCompletado = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.registro.btnRegistroCompletado);
};

/**
 * Metodo que envia un evento tag al presionar btn vuelve al inicio.
 */
export const envioEventoTagRegistroVolverInicio = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.registro.btnRegistroVolverInicio);
};

/**
 * Metodo que envia un evento tag al presionar btn ingresar migracion
 */
export const envioEventoTagIngresarMigracionExitoso = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.migracion.btnIniciarMigracionExitoso);
};

/**
 * Metodo que envia un evento tag al presionar btn ingresar migracion
 */
export const envioEventoTagIngresarMigracionFallido = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.migracion.btnIniciarMigracionFallido);
};

/**
 * Metodo que envia tag al ingresar con google de forma exitosa
 */
export const envioEventoTagIngresarMigracionCuentaGoogleExitoso = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.migracion.btnIngresoMigracionCuentaGoogleExitoso);
};

/**
 * Metodo que envia tag al ingresar con google de forma fallida
 */
export const envioEventoTagIngresarMigracionCuentaGoogleFallido = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.migracion.btnIngresoMigracionCuentaGoogleFallido);
};

/**
 * Metodo que envia tag al ingresar con email
 */
export const envioEventoTagIngresarMigracionEmailExitoso = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.migracion.btnIngresoMigracionEmailExitoso);
};

/**
 * Metodo que envia tag al ingresar con email
 */
export const envioEventoTagIngresarMigracionEmailFallido = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.migracion.btnIngresoMigracionEmailFallido);
};

/**
 * Metodo que envia tag al ingresar con email
 */
export const envioEventoTagIngresarMigracionValidacionEmailFallido = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.migracion.btnIngresoMigracionFallidoAvanzaValidacionEmail);
};

/**
 * Metodo que envia tag al ingresar con email
 */
export const envioEventoTagIngresarMigracionCompletarDatosPersonalesFallido = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.migracion.btnIngresoMigracionFallidoAvanzaDatosPersonales);
};

/**
 * Metodo que envia tag al ingresar con email
 */
export const envioEventoTagIngresarMigracionConsentimientoFallido = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.migracion.btnIngresoMigracionFallidoAvanzaConsentimiento);
};

/**
 * Metodo que envia tag al ingresar con email de forma forzada
 */
export const envioEventoTagIngresarMigracionEmailForzado = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.migracion.btnIngresoMigracionEmailForzado);
};

/**
 * Metodo que envia tag al ingresar con google de forma forzada
 */
export const envioEventoTagIngresarMigracionCuentaGoogleForzado = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.migracion.btnIngresoMigracionCuentaGoogleForzado);
};

/**
 * Metodo que envia tag al enviar datos personales de forma exitosa
 */
export const envioEventoTagMigracionDatosPersonalesExitoso = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.migracion.btnMigracionDatosPersonalesExitoso);
};

/**
 * Metodo que envia tag al enviar datos personales de forma fallida
 */
export const envioEventoTagMigracionDatosPersonalesFallido = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.migracion.btnMigracionDatosPersonalesFallido);
};

/**
 * Metodo que envia tag al enviar consentimiento de boleta de forma exitosa
 */
export const envioEventoTagMigracionEnvioConsentimientoBoletaExitoso = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.migracion.btnMigracionEnvioConsentimientoBoletaExitoso);
};

/**
 * Metodo que envia un evento tag redirecciona a datos personales
 */
export const envioEventoTagMigracionRedireccionarDatosPersonales = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.migracion.migracionRedireccionarDatosPersonales);
};

/**
 * Metodo que envia un evento tag redirecciona a validacion email
 */
export const envioEventoTagMigracionRedireccionarValidacionEmail = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.migracion.migracionRedireccionarValidacionEmail);
};

/**
 * Metodo que envia un evento tag redirecciona a consentimiento
 */
export const envioEventoTagMigracionRedireccionarConsentimientoBoleta = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.migracion.migracionRedireccionarConsentimientoBoleta);
};

/**
 * Metodo que envia tag al enviar consentimiento de boleta de forma fallida
 */
export const envioEventoTagMigracionEnvioConsentimientoBoletaFallido = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.migracion.btnMigracionEnvioConsentimientoBoletaFallido);
};

/**
 * Metodo que envia tag migracion ya iniciada
 */
export const envioEventoTagMigracionYaIniciada = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.migracion.btnMigracionYaIniciada);
};

/**
 * Metodo que envia tag migracion ya registrada
 */
export const envioEventoTagMigracionYaRegistrada = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.migracion.btnMigracionYaRegistrada);
};

/**
 * Metodo que envia tag al completar migracion
 */
export const envioEventoTagMigracionCompletada = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.migracion.btnMigracionCompletada);
};

/**
 * Metodo que envia un evento tag al presionar btn vuelve al inicio.
 */
export const envioEventoTagMigracionVolverInicio = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.migracion.btnMigracionVolverInicio);
};

/**
 * Metodo que envia un evento tag al ingresar a la página de registro.
 */
export const envioEventoTagRegistroCargaInicial = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.registro.cargaInicialRegistro);
};

/**
 * Envía un evento tag al ingresar a la página de registro.
 * @param tipoIngreso - El tipo de ingreso del usuario.
 */
export const envioEventoTagRegistroIngresoDatosPersonales = (tipoIngreso: TipoIngreso | null) => {
  if (!tipoIngreso) {
    return;
  }
  const tag = EventosGtmSaesa.registro.ingresoDatosPersonales;
  tag.evento = tag.evento.replace('{tipoIngreso}', TipoIngresoMap[tipoIngreso]);
  AnalyticsService.enviarTag(tag);
};

/**
 * Envía un evento tag al hacer clic en el botón de validar número de servicio en la página de registro.
 * @param tipoIngreso - El tipo de ingreso del usuario.
 */
export const envioEventoTagRegistroBtnValidarNumeroServicioDatosPersonales = (tipoIngreso: TipoIngreso | null) => {
  if (!tipoIngreso) {
    return;
  }
  const tag = EventosGtmSaesa.registro.btnValidarNumeroServicioDatosPersonales;
  tag.evento = tag.evento.replace('{tipoIngreso}', TipoIngresoMap[tipoIngreso]);
  AnalyticsService.enviarTag(tag);
};

/**
 * Envía un evento tag al hacer clic en el botón de continuar en la página de registro y completar los datos personales.
 * @param tipoIngreso - El tipo de ingreso del usuario.
 */
export const envioEventoTagRegistroBtnContinuarDatosPersonales = (tipoIngreso: TipoIngreso | null) => {
  if (!tipoIngreso) {
    return;
  }
  const tag = EventosGtmSaesa.registro.btnContinuarDatosPersonales;
  tag.evento = tag.evento.replace('{tipoIngreso}', TipoIngresoMap[tipoIngreso]);
  AnalyticsService.enviarTag(tag);
};

/**
 * Envía un evento tag al ingresar a la página de registro.
 * @param tipoIngreso - El tipo de ingreso del usuario.
 */
export const envioEventoTagRegistroIngresoBoletaElectronica = (tipoIngreso: TipoIngreso | null) => {
  if (!tipoIngreso) {
    return;
  }
  const tag = EventosGtmSaesa.registro.ingresoBoletaElectronica;
  tag.evento = tag.evento.replace('{tipoIngreso}', TipoIngresoMap[tipoIngreso]);
  AnalyticsService.enviarTag(tag);
};

/**
 * Envía un evento tag al hacer clic en el botón de continuar en la página de registro.
 * @param tipoIngreso - El tipo de ingreso del usuario.
 */
export const envioEventoTagRegistroBtnContinuarBoletaElectronica = (tipoIngreso: TipoIngreso | null) => {
  if (!tipoIngreso) {
    return;
  }
  const tag = EventosGtmSaesa.registro.btnContinuarBoletaElectronica;
  tag.evento = tag.evento.replace('{tipoIngreso}', TipoIngresoMap[tipoIngreso]);
  AnalyticsService.enviarTag(tag);
};

/**
 * Envía un evento tag al finalizar el registro después de completar todos los pasos.
 * @param tipoIngreso - El tipo de ingreso del usuario.
 */
export const envioEventoTagRegistroIngresoRegistroFinalizado = (tipoIngreso: TipoIngreso | null) => {
  if (!tipoIngreso) {
    return;
  }
  const tag = EventosGtmSaesa.registro.ingresoRegistroFinalizado;
  tag.evento = tag.evento.replace('{tipoIngreso}', TipoIngresoMap[tipoIngreso]);
  AnalyticsService.enviarTag(tag);
};

/**
 * Envía un evento tag al hacer clic en el botón de iniciar sesión finalizando el registro.
 * @param tipoIngreso - El tipo de ingreso del usuario.
 */
export const envioEventoTagRegistroBtnIniciarSesionRegistroFinalizado = (tipoIngreso: TipoIngreso | null) => {
  if (!tipoIngreso) {
    return;
  }
  const tag = EventosGtmSaesa.registro.btnIniciarSesionRegistroFinalizado;
  tag.evento = tag.evento.replace('{tipoIngreso}', TipoIngresoMap[tipoIngreso]);
  AnalyticsService.enviarTag(tag);
};

/**
 * Envía un evento tag al hacer clic en el botón de registrarme con otros correos electrónicos.
 */
export const envioEventoTagRegistroBtnRegistrarmeOtrosCorreos = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.registro.btnRegistrarmeOtrosCorreos);
};

/**
 * Envía un evento tag al ingresar a la página de validación de correo electrónico con otros correos electrónicos.
 */
export const envioEventoTagRegistroIngresoValidacionEmailOtrosCorreos = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.registro.ingresoValidacionEmailOtrosCorreos);
};

/**
 * Envía un evento tag al hacer clic en el botón de continuar en la página de validación de email con otros correos.
 */
export const envioEventoTagRegistroBtnContinuarValidacionEmailOtrosCorreos = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.registro.btnContinuarValidacionEmailOtrosCorreos);
};

/**
 * Envía un evento tag al ingresar a la página de para completar los datos personales con otros correos electrónicos.
 */
export const envioEventoTagRegistroIngresoDatosPersonalesOtrosCorreos = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.registro.ingresoDatosPersonalesOtrosCorreos);
};

/**
 * Envía un evento tag al hacer clic en el botón de validar número de servicio en la página de registro
 * con otros correos electrónicos.
 */
export const envioEventoTagRegistroBtnValidarNumeroServicioDatosPersonalesOtrosCorreos = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.registro.btnValidarNumeroServicioDatosPersonalesOtrosCorreos);
};

/**
 * Envía un evento tag al hacer clic en el botón de continuar en la página de registro y completar los datos personales con otros correos electrónicos.
 */
export const envioEventoTagRegistroBtnContinuarDatosPersonalesOtrosCorreos = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.registro.btnContinuarDatosPersonalesOtrosCorreos);
};

/**
 * Envía un evento tag al ingresar a la página de registro y completar la información de la boleta electrónica con otros correos electrónicos.
 */
export const envioEventoTagRegistroIngresoBoletaElectronicaOtrosCorreos = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.registro.ingresoBoletaElectronicaOtrosCorreos);
};

/**
 * Envía un evento tag al hacer clic en el botón de continuar en la página de registro y
 * completar la información de la boleta electrónica con otros correos electrónicos.
 */
export const envioEventoTagRegistroBtnContinuarBoletaElectronicaOtrosCorreos = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.registro.btnContinuarBoletaElectronicaOtrosCorreos);
};

/**
 * Envía un evento tag al finalizar el registro después de completar todos los pasos con otros correos electrónicos.
 */
export const envioEventoTagRegistroIngresoRegistroFinalizadoOtrosCorreos = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.registro.ingresoRegistroFinalizadoOtrosCorreos);
};

/**
 * Envía un evento tag al hacer clic en el botón de iniciar sesión después de finalizar el registro con otros correos electrónicos.
 */
export const envioEventoTagRegistroBtnIniciarSesionRegistroFinalizadoOtrosCorreos = () => {
  AnalyticsService.enviarTag(EventosGtmSaesa.registro.btnIniciarSesionRegistroFinalizadoOtrosCorreos);
};
