export const EventosGtmSaesa = {
  registro: {
    btnIngresarCuentaApple: {
      evento: 'registro_btn_ingresar_apple_click',
      nombre: 'Botón ingresar con cuenta apple ',
      categoria: 'registro',
    },
    btnIngresarCuentaAppleRedireccionValidacionCliente: {
      evento: 'registro_btn_ingresar_apple_redireccion_validacion_cliente_click',
      nombre: 'Botón ingresar con cuenta apple redirecciona a validacion cliente ',
      categoria: 'registro',
    },
    btnIngresarCuentaAppleRedireccionDatosPersonales: {
      evento: 'registro_btn_ingresar_apple_redireccion_datos_personales_click',
      nombre: 'Botón ingresar con cuenta apple redirecciona a datos personales ',
      categoria: 'registro',
    },
    btnRegistroOnboardingVolverARedireccion: {
      evento: 'registro_btn_volver_redireccion_click',
      nombre: 'Botón que retorna a vista de redireccion ',
      categoria: 'registro',
    },
    btnIngresarCuentaAppleRedireccionConsentimientoBoleta: {
      evento: 'registro_btn_ingresar_apple_redireccion_consentimiento_boleta_click',
      nombre: 'Botón ingresar con cuenta apple redirecciona a consentimiento de boleta ',
      categoria: 'registro',
    },
    btnIngresarCuentaAppleErrorRedireccionInicio: {
      evento: 'registro_btn_ingresar_apple_error_redireccion_inicio_click',
      nombre: 'Botón ingresar con cuenta apple error redirecciona a inicio ',
      categoria: 'registro',
    },
    btnIngresarLogin: {
      evento: 'registro_btn_ingresar_login_click',
      nombre: 'Botón ingresar login ',
      categoria: 'registro',
    },
    btnIngresoExitosoCuentaGoogle: {
      evento: 'registro_btn_ingresar_google_click',
      nombre: 'Botón ingreso exitoso con cuenta google ',
      categoria: 'registro',
    },
    btnIngresoFallidoCuentaGoogle: {
      evento: 'registro_btn_ingreso_fallido_google_click',
      nombre: 'Botón ingreso exitoso con cuenta google ',
      categoria: 'registro',
    },
    btnIngresoForzadoCuentaGoogle: {
      evento: 'registro_btn_ingreso_forzado_google_click',
      nombre: 'Botón ingreso forzado con cuenta google ',
      categoria: 'registro',
    },
    btnRegistroOnboardingExitoso: {
      evento: 'registro_btn_Registrarme_exitoso_click',
      nombre: 'Botón registro onboarding exitoso',
      categoria: 'registro',
    },
    btnRegistroOnboardingFallido: {
      evento: 'registro_btn_Registrarme_fallido_click',
      nombre: 'Botón registro onboarding fallido',
      categoria: 'registro',
    },
    registroOnboardingUsuarioYaRegistrado: {
      evento: 'registro_registro_onboarding_error_usuario_registrado',
      nombre: 'Registro onboarding error usuario ya registrado',
      categoria: 'registro',
    },
    btnRegistroOnboardingFallidoAvanzaValidacionEmail: {
      evento: 'registro_btn_ingreso_onboarding_fallido_avanza_validacion_email_click',
      nombre: 'Botón ingreso registro fallida avanza a validacion email',
      categoria: 'registro',
    },
    btnRegistroOnboardingFallidoAvanzaDatosPersonales: {
      evento: 'registro_btn_ingreso_onboarding_fallido_avanza_datos_personales_click',
      nombre: 'Botón ingreso registro fallida avanza a datos personales',
      categoria: 'registro',
    },
    btnRegistroOnboardingFallidoAvanzaConsentimiento: {
      evento: 'registro_btn_ingreso_onboarding_fallido_avanza_consentimiento_click',
      nombre: 'Botón ingreso registro fallida avanza a consentimiento',
      categoria: 'registro',
    },
    btnRegistroOnboardingValidacionEmailFallido: {
      evento: 'registro_btn_Registrarme_validacion_email_fallido',
      nombre: 'Botón registro onboarding validacion email fallido',
      categoria: 'registro',
    },
    btnRegistroOnboardingCompletarDatosFallido: {
      evento: 'registro_btn_Registrarme_completar_datos_fallido',
      nombre: 'Botón registro onboarding completar datos fallido',
      categoria: 'registro',
    },
    btnRegistroOnboardingConsentimientoFallido: {
      evento: 'registro_btn_Registrarme_consentimiento_fallido',
      nombre: 'Botón registro onboarding consentimiento fallido',
      categoria: 'registro',
    },
    btnRegistroOnboardingForzado: {
      evento: 'registro_btn_Registrarme_forzado_click',
      nombre: 'Botón registro onboarding de manera forzada',
      categoria: 'registro',
    },
    btnValidaEmailRegistroExistoso: {
      evento: 'registro_btn_valida_email_exitoso_click',
      nombre: 'Botón valida email registro exitoso ',
      categoria: 'registro',
    },
    btnValidaEmailRegistroExpirado: {
      evento: 'registro_btn_valida_email_expirado_click',
      nombre: 'Botón valida email registro codigo expirado ',
      categoria: 'registro',
    },
    ingresoVistaExpiracionEmailNumeroIntentosPermitidos: {
      evento: 'registro_vista_expiracion_email_numero_intentos_permitidos',
      nombre: 'Vista expiracion email numero de intentos permitidos ',
      categoria: 'registro',
    },
    btnEnviarCodigoConfirmacion: {
      evento: 'registro_enviar_codigo_confirmacion',
      nombre: 'Ingreso vista de validacion email y envio de codigo de confirmación ',
      categoria: 'registro',
    },
    btnReenviarCodigoConfirmacion: {
      evento: 'registro_btn_reenviar_codigo_confirmacion_click',
      nombre: 'Botón reenvio de codigo de confirmación ',
      categoria: 'registro',
    },
    btnValidarCliente: {
      evento: 'registro_btn_validar_cliente_numero_click',
      nombre: 'Botón validar numero de cliente ',
      categoria: 'registro',
    },
    btnRegistroDatosPersonalesExitoso: {
      evento: 'registro_btn_registro_datos_personales_exitoso_click',
      nombre: 'Botón registro datos personales de cliente exitoso ',
      categoria: 'registro',
    },
    btnRegistroDatosPersonalesFallido: {
      evento: 'registro_btn_registro_datos_personales_fallido_click',
      nombre: 'Botón registro datos personales de cliente fallido ',
      categoria: 'registro',
    },
    btnRegistroEnvioConsentimientoBoletaExitoso: {
      evento: 'registro_btn_registro_envio_consentimiento_boleta_exitoso_click',
      nombre: 'Botón envio de consentimiento de boleta exitoso ',
      categoria: 'registro',
    },
    btnRegistroEnvioConsentimientoBoletaFallido: {
      evento: 'registro_btn_registro_envio_consentimiento_boleta_fallido_click',
      nombre: 'Botón envio de consentimiento de boleta fallido ',
      categoria: 'registro',
    },
    btnRegistroCompletado: {
      evento: 'registro_btn_registro_completado_click',
      nombre: 'Botón completar registro ',
      categoria: 'registro',
    },
    btnRegistroVolverInicio: {
      evento: 'registro_btn_volver_inicio_click',
      nombre: 'Botón volver inicio registro ',
      categoria: 'registro',
    },
    cargaInicialRegistro: {
      evento: 'registro_accesos_landing_registro',
      nombre: 'Carga inicial de página de registro',
      categoria: 'registro',
    },
    ingresoDatosPersonales: {
      evento: 'registro_{tipoIngreso}_paso_dos_ingreso',
      nombre: 'Carga inicial de ingreso de datos personales',
      categoria: 'registro',
    },
    btnValidarNumeroServicioDatosPersonales: {
      evento: 'registro_{tipoIngreso}_paso_dos_validar',
      nombre: 'Botón validar numero de servicio',
      categoria: 'registro',
    },
    btnContinuarDatosPersonales: {
      evento: 'registro_{tipoIngreso}_paso_dos_Finalizar',
      nombre: 'Botón continuar datos personales',
      categoria: 'registro',
    },
    ingresoBoletaElectronica: {
      evento: 'registro_{tipoIngreso}_paso_tres_ingreso',
      nombre: 'Ingreso a boleta electrónica',
      categoria: 'registro',
    },
    btnContinuarBoletaElectronica: {
      evento: 'registro_{tipoIngreso}_paso_tres_RegistroOk',
      nombre: 'Botón continuar boleta electrónica',
      categoria: 'registro',
    },
    ingresoRegistroFinalizado: {
      evento: 'registro_{tipoIngreso}_paso_cuatro_ingreso',
      nombre: 'Ingreso a boleta electrónica otros correos',
      categoria: 'registro',
    },
    btnIniciarSesionRegistroFinalizado: {
      evento: 'registro_{tipoIngreso}_paso_cuatro_login',
      nombre: 'Botón iniciar sesión registro finalizado',
      categoria: 'registro',
    },
    btnRegistrarmeOtrosCorreos: {
      evento: 'registro_otrosCorreos_inicio_registro',
      nombre: 'Botón iniciar sesión registro finalizado',
      categoria: 'registro',
    },
    ingresoValidacionEmailOtrosCorreos: {
      evento: 'registro_otrosCorreos_paso_dos_ingreso',
      nombre: 'Ingreso validación email otros correos',
      categoria: 'registro',
    },
    btnContinuarValidacionEmailOtrosCorreos: {
      evento: 'registro_otrosCorreos_paso_dos_continuar',
      nombre: 'Botón continuar validación email otros correos',
      categoria: 'registro',
    },
    ingresoDatosPersonalesOtrosCorreos: {
      evento: 'registro_otrosCorreos_paso_tres_ingreso',
      nombre: 'Carga inicial de ingreso de datos personales otros correos',
      categoria: 'registro',
    },
    btnValidarNumeroServicioDatosPersonalesOtrosCorreos: {
      evento: 'registro_otrosCorreos_paso_tres_validar',
      nombre: 'Botón validar numero de servicio otros correos',
      categoria: 'registro',
    },
    btnContinuarDatosPersonalesOtrosCorreos: {
      evento: 'registro_otrosCorreos_paso_tres_continuar',
      nombre: 'Botón continuar datos personales otros correos',
      categoria: 'registro',
    },
    ingresoBoletaElectronicaOtrosCorreos: {
      evento: 'registro_otrosCorreos_paso_cuatro_ingreso',
      nombre: 'Ingreso a boleta electrónica otros correos',
      categoria: 'registro',
    },
    btnContinuarBoletaElectronicaOtrosCorreos: {
      evento: 'registro_otrosCorreos_paso_cuatro_RegistroOk',
      nombre: 'Botón continuar boleta electrónica otros correos',
      categoria: 'registro',
    },
    ingresoRegistroFinalizadoOtrosCorreos: {
      evento: 'registro_otrosCorreos_paso_cinco_ingreso',
      nombre: 'Ingreso a registro finalizado otros correos',
      categoria: 'registro',
    },
    btnIniciarSesionRegistroFinalizadoOtrosCorreos: {
      evento: 'registro_otrosCorreos_paso_cinco_login',
      nombre: 'Botón iniciar sesión registro finalizado otros correos',
      categoria: 'registro',
    },
  },

  migracion: {
    btnIniciarMigracionExitoso: {
      evento: 'registro_btn_iniciar_migracion_exitoso_click',
      nombre: 'Botón iniciar migracion exitoso',
      categoria: 'migracion',
    },
    btnIniciarMigracionFallido: {
      evento: 'registro_btn_iniciar_migracion_Fallido_click',
      nombre: 'Botón iniciar migracion fallido',
      categoria: 'migracion',
    },
    btnIngresoMigracionCuentaGoogleExitoso: {
      evento: 'registro_btn_ingreso_migracion_cuenta_google_exitoso_click',
      nombre: 'Botón ingreso migracion con cuenta google exitoso ',
      categoria: 'migracion',
    },
    btnIngresoMigracionCuentaGoogleFallido: {
      evento: 'registro_btn_ingreso_migracion_cuenta_google_fallido_click',
      nombre: 'Botón ingreso migracion con cuenta google fallido ',
      categoria: 'migracion',
    },
    btnIngresoMigracionCuentaGoogleForzado: {
      evento: 'registro_btn_ingreso_migracion_cuenta_google_forzado_click',
      nombre: 'Botón ingreso migracion con cuenta google forzado ',
      categoria: 'migracion',
    },
    btnIngresoMigracionEmailExitoso: {
      evento: 'registro_btn_ingreso_migracion_email_exitoso_click',
      nombre: 'Botón ingreso migracion con email exitoso',
      categoria: 'migracion',
    },
    btnIngresoMigracionEmailFallido: {
      evento: 'registro_btn_ingreso_migracion_email_fallido_click',
      nombre: 'Botón ingreso migracion con email fallido',
      categoria: 'migracion',
    },
    btnIngresoMigracionFallidoAvanzaValidacionEmail: {
      evento: 'registro_btn_ingreso_migracion_fallido_avanza_validacion_email_click',
      nombre: 'Botón ingreso migracion fallida avanza a validacion email',
      categoria: 'migracion',
    },
    btnIngresoMigracionFallidoAvanzaDatosPersonales: {
      evento: 'registro_btn_ingreso_migracion_fallido_avanza_datos_personales_click',
      nombre: 'Botón ingreso migracion fallida avanza a datos personales',
      categoria: 'migracion',
    },
    btnIngresoMigracionFallidoAvanzaConsentimiento: {
      evento: 'registro_btn_ingreso_migracion_fallido_avanza_consentimiento_click',
      nombre: 'Botón ingreso migracion fallida avanza a consentimiento',
      categoria: 'migracion',
    },
    btnIngresoMigracionEmailForzado: {
      evento: 'registro_btn_ingreso_migracion_email_forzado_click',
      nombre: 'Botón ingreso migracion con email forzado ',
      categoria: 'migracion',
    },
    btnMigracionDatosPersonalesExitoso: {
      evento: 'migracion_btn_migracion_datos_personales_exitoso_click',
      nombre: 'Botón migracion datos personales de cliente exitoso ',
      categoria: 'migracion',
    },
    btnMigracionDatosPersonalesFallido: {
      evento: 'migracion_btn_migracion_datos_personales_fallido_click',
      nombre: 'Botón migracion datos personales de cliente fallido ',
      categoria: 'migracion',
    },
    btnMigracionEnvioConsentimientoBoletaExitoso: {
      evento: 'migracion_btn_migracion_envio_consentimiento_boleta_exitoso_click',
      nombre: 'Botón migracion envio consentimiento de boleta exitoso ',
      categoria: 'migracion',
    },
    btnMigracionEnvioConsentimientoBoletaFallido: {
      evento: 'migracion_btn_migracion_envio_consentimiento_boleta_fallido_click',
      nombre: 'Botón migracion envio consentimiento de boleta fallido ',
      categoria: 'migracion',
    },
    migracionRedireccionarDatosPersonales: {
      evento: 'migracion_redirecciona_datos_personales',
      nombre: 'Migracion redireccion a vista de datos personales',
      categoria: 'migracion',
    },
    migracionRedireccionarValidacionEmail: {
      evento: 'migracion_redirecciona_validacion_email',
      nombre: 'Migracion redireccion a vista de validacion email',
      categoria: 'migracion',
    },
    migracionRedireccionarConsentimientoBoleta: {
      evento: 'migracion_redirecciona_consentimiento_boleta',
      nombre: 'Migracion redireccion a vista de consentimiento de boleta',
      categoria: 'migracion',
    },
    btnMigracionCompletada: {
      evento: 'migracion_btn_migracion_completada_click',
      nombre: 'Botón completar migracion ',
      categoria: 'migracion',
    },
    btnMigracionVolverInicio: {
      evento: 'migracion_btn_volver_inicio_click',
      nombre: 'Botón volver inicio migracion ',
      categoria: 'migracion',
    },
    btnMigracionYaIniciada: {
      evento: 'migracion_error_migracion_ya_iniciada',
      nombre: 'Error migracion ya iniciada ',
      categoria: 'migracion',
    },
    btnMigracionYaRegistrada: {
      evento: 'migracion_error_migracion_ya_registrada',
      nombre: 'Error migracion ya registrada ',
      categoria: 'migracion',
    },
  },
};
