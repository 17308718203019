import { Loading } from '@saesa/ui-kit-front';
import { ErrorInterceptor } from 'config';
import { ROOT } from 'navigation';
import { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { MIGRACION, OLVIDE_PASSWORD, POLITICA_PRIVACIDAD, REGISTRO } from './constants';
import NotFound from './NotFound/NotFound';

const RootRouterConfig = () => {
  const PaginaOnBoarding = lazy(() => import('pages/OnBoarding/navigation/RouterConfig'));
  const PaginaMigracion = lazy(() => import('pages/Migracion/navigation/RouterConfig'));
  /**
   * Pagina de politicas de privacidad
   */
  const PaginaPoliticiasPrivacidad = lazy(() => import('pages/PoliticasPrivacidad/PoliticasPrivacidad'));
  /**
   * Pagina de olvide mi password
   */
  const PaginaOlvideMiContrasenia = lazy(() => import('pages/OlvideMiContrasenia/OlvideMiContrasenia'));

  const { t } = useTranslation();
  const history = useHistory();
  ErrorInterceptor(t, history);

  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Redirect path={ROOT} exact to={REGISTRO} />
        <Route path={REGISTRO}>
          <PaginaOnBoarding path={REGISTRO} />
        </Route>
        <Route path={POLITICA_PRIVACIDAD}>
          <PaginaPoliticiasPrivacidad />
        </Route>
        <Route path={OLVIDE_PASSWORD}>
          <PaginaOlvideMiContrasenia />
        </Route>
        <Route path={MIGRACION}>
          <PaginaMigracion path={MIGRACION} />
        </Route>
        <Route path="*" component={NotFound} />
      </Switch>
    </Suspense>
  );
};

export default RootRouterConfig;
